
import { NavigationItem } from "@/store/modules/Base/model";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class DrawerMenu extends Vue {
  @Prop({ required: true }) menuItems!: NavigationItem[];
  @Prop({ required: true }) opened!: boolean;

  onMenuItemClicked(selected: string): void {
    this.$emit("selected", { item: selected });
  }

  get heightClass(): string {
    return this.opened ? "drawer-opened" : "drawer-closed";
  }
}
