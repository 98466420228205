import { EventCodeContentModel, EventCodeModel } from "@/store/modules/Event/model";
import { http } from "@/services/http";
import responses from "@/services/responses";
import routes from "@/services/routes";

export const registrationCode = {
  getCodesById(id: string): Promise<EventCodeContentModel[]> {
    return http
      .get<EventCodeModel>(`${routes().REGISTRATION}/?trainingEventId=${id}&size=9999999`)
      .then((response) => response.data.content)
      .catch((error) => {
        if (error.response && error.response.status === 404) console.clear();
        return [];
      });
  },

  redeem(code: string): Promise<string> {
    return http
      .post(`${routes().REGISTRATION}`, { code })
      .then(() => {
        return responses.SUCCESS;
      })
      .catch((err) => {
        return err.response.data.error;
      });
  },

  validate(code: string): Promise<{ res: string; type: string }> {
    return http
      .post<{ valid: boolean; type: string }>(`${routes().REGISTRATION}/validate`, { code })
      .then((response) => {
        const type = response.data.type;
        return { res: responses.SUCCESS, type };
      })
      .catch((error) => {
        return { res: `${error.response.data.errorCode}`, type: "" };
      });
  },
};
