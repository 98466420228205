
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import UserMenu from "@/components/Header/User/UserMenu.vue";
import SystemStatus from "@/components/SystemStatus/SystemStatus.vue";

@Component({
  components: { UserMenu, SystemStatus },
  computed: { ...mapState(["headerTitle"]) },
})
export default class LightHeader extends Vue {}
