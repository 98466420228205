
import Icon from "@/components/Icons/Icon.vue";
import { BaseSidebarSettings, NavigationItem } from "@/store/modules/Base/model";
import { SetActiveStep } from "@/store/modules/Step/model";
import { Component, Vue } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";

@Component({
  components: { Icon },
  computed: {
    ...mapState(["navItem"]),
    ...mapState("BaseModule", ["sidebar"]),
    ...mapState("StepModule", ["activeStep", "completedSteps", "totalSteps", "isEditing", "steps"]),
  },
  methods: {
    ...mapMutations("StepModule", ["setActiveStep"]),
  },
})
export default class BaseSidebarStep extends Vue {
  private readonly navItem?: NavigationItem;
  private readonly sidebar!: BaseSidebarSettings;
  private readonly setActiveStep!: SetActiveStep;
  private readonly isEditing!: boolean;

  get title(): string {
    if (!this.isEditing) return this.navItem?.sidebar?.title || this.sidebar.step.title;
    else return this.navItem?.sidebar?.editing || this.sidebar.step.title;
  }

  onStepClick(step: number): void {
    this.setActiveStep(step);
  }
}
