
import { Component, ProvideReactive, Vue } from "vue-property-decorator";
import { mapState, mapMutations } from "vuex";
import BaseFooter from "@/components/Footer/BaseFooter.vue";
import BaseHeader from "@/components/Header/BaseHeader.vue";
import BaseSidebar from "@/components/Sidebar/BaseSidebar.vue";
import { BasePopup as Popup } from "@cruciallearning/puddle";
import SessionTimeout from "@/components/DialogueBoxes/SessionTimeout.vue";

@Component({
  components: { BaseHeader, BaseSidebar, BaseFooter, Popup, SessionTimeout },
  computed: {
    ...mapState(["backgroundColorClass", "isSidebarVisible", "isFooterVisible", "sidebarType", "isReady"]),
    ...mapState("BaseModule", ["error", "popupText", "popupTitle"]),
  },
  methods: {
    ...mapMutations("BaseModule", ["resetError", "resetPopupText"]),
  },
})
export default class App extends Vue {
  @ProvideReactive() toggleSidebarCallback = this.toggleSidebar;

  private isSidebarMenuVisible = false;
  private readonly error!: string;
  private readonly resetError!: () => void;
  toggleSidebar(isVisible: boolean): void {
    this.isSidebarMenuVisible = isVisible;
  }
}
