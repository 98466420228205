
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import BaseSidebarStep from "@/components/Sidebar/Step/BaseSidebarStep.vue";

@Component({
  components: { BaseSidebarStep },
  computed: { ...mapState(["sidebarType"]) },
})
export default class BaseSidebar extends Vue {}
