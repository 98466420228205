import api from "@/services/api";
import { ActionContext, Module } from "vuex";
import { FilterModel, FilterState } from "./model";
const BaseModule: Module<FilterState, unknown> = {
  namespaced: true,
  state() {
    return {
      filters: [],
      search: "",
      dateRangeFilter: "",
      startDateRange: "",
      endDateRange: "",
      eventFormatFilter: "",
      myEventFilter: "",
      page: 0,
    };
  },
  mutations: {
    setFilters(state: FilterState, filters: FilterModel[]): void {
      state.filters = JSON.parse(JSON.stringify(state.filters.concat(filters)));
    },
    setFilterState(state: FilterState, filterStateOptions: Partial<FilterState>): void {
      Object.assign(state, filterStateOptions);
    },
    setFiltersActiveById(state: FilterState, filterOptions: Partial<FilterModel> | Partial<FilterModel[]>): void {
      if (!Array.isArray(filterOptions)) filterOptions = [{ ...filterOptions }] as Partial<FilterModel[]>;
      filterOptions.map((filterChange) => {
        const filterIndex = state.filters.findIndex((filter) => filter.id === filterChange?.id);
        if (filterIndex >= 0) state.filters[filterIndex].active = filterChange?.active || false;
      });
    },
  },
  actions: {
    async getFilters({ commit }: ActionContext<FilterState, unknown>, filterType: number): Promise<void> {
      const filters = await api.filter.getFilters(filterType);
      commit("setFilters", filters);
    },
  },
};

export default BaseModule;
