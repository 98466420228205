import config from "@/config";

/*
 * ------------------------------------------------------------------------------
 * Osano script definitions
 * ------------------------------------------------------------------------------
 */
const Model = {
  get cm(): OsanoFunctions {
    const osanoWindow = window as unknown as OsanoWindow;
    return osanoWindow.Osano.cm;
  },
};

export interface OsanoWindow {
  Osano: OsonoCm;
}
export interface OsonoCm {
  cm: OsanoFunctions;
}
export interface OsanoFunctions {
  showDrawer(options: string): void;
}

export default Model;
