
import { BaseFooterSettings, NavigationItem } from "@/store/modules/Base/model";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";
import { SetActiveStep, SetCompletedStep, SetStepState } from "@/store/modules/Step/model";
import { BaseButton as Button } from "@cruciallearning/puddle";
import { EventContentModel } from "@cruciallearning/puddle/models/event";
import Icon from "@/components/Icons/Icon.vue";

@Component({
  components: { Button, Icon },
  computed: {
    ...mapState(["lastRoute", "navItem"]),
    ...mapState("BaseModule", ["footer"]),
    ...mapState("StepModule", [
      "activeStep",
      "completedSteps",
      "totalSteps",
      "isEditing",
      "isComplete",
      "isStepValid",
      "validChecks",
      "cancelConfirmed",
      "confirmCancel",
      "disableNext",
    ]),
    ...mapState("EventModule", ["event"]),
    ...mapGetters("EventModule", ["getClientName", "getAvailableLicenses"]),
  },
  methods: {
    ...mapMutations("StepModule", ["setActiveStep", "setCompletedStep", "setCurrentComponent", "setStepState"]),
  },
})
export default class BaseFooterStep extends Vue {
  private readonly activeStep!: number;
  private readonly footer!: BaseFooterSettings;
  private readonly isStepValid!: boolean;
  private readonly lastRoute!: string;
  private readonly navItem?: NavigationItem;
  private readonly totalSteps!: number;
  private readonly validChecks!: Map<number, () => boolean>;
  private readonly confirmCancel!: boolean;
  private readonly cancelConfirmed!: boolean;
  private readonly setStepState!: SetStepState;
  private readonly setActiveStep!: SetActiveStep;
  private readonly setCompletedStep!: SetCompletedStep;
  private readonly getClientName!: boolean;
  private readonly getAvailableLicenses!: number;
  private savedSeatCount = 0;
  private event!: EventContentModel;
  private codesRedeemed = 0;

  private isSaving = false;
  private isSaved = false;

  private goBack = false;

  mounted() {
    if (this.event.id) {
      if (this.event.registrationType === "REGISTRATION") {
        this.codesRedeemed = this.event.redeemedCount;
      }
    }
  }

  get isFirstStep(): boolean {
    return this.activeStep === 0;
  }

  get isLastStep(): boolean {
    return this.activeStep + 1 === this.totalSteps;
  }

  onDiscard() {
    if (this.confirmCancel) {
      this.setStepState({
        showConfirmPopup: true,
      });
    } else {
      this.completeCancel();
    }
  }

  completeCancel(): void {
    this.setStepState({
      confirmCancel: false,
      cancelConfirmed: false,
      showConfirmPopup: false,
    });
    const route = this.navItem?.footer?.route || this.lastRoute || "/";
    this.$router.push(route).catch(() => undefined);
  }

  onFormSubmit(event: InputEvent): void {
    event.preventDefault();
    if (!this.isStepValid) return;
    if (this.goBack) {
      this.setActiveStep(this.activeStep - 1);
    } else if (!this.isLastStep) {
      this.setCompletedStep(this.activeStep);
      this.setActiveStep(this.activeStep + 1);
      this.setStepState({ disableNext: false });
    } else {
      this.setCompletedStep(this.activeStep);
      this.setStepState({ disableNext: false });
    }
  }

  get isVirtual(): boolean {
    return this.event.learningFormatType === this.$constants.eventType.virtual;
  }
  get seatCountMax(): number {
    if (this.event.id) {
      this.savedSeatCount = this.event.seatCount;
    }
    let max = this.getAvailableLicenses + this.savedSeatCount;
    if (this.isVirtual) {
      max = Math.min(max, 30);
    }
    return max;
  }
  get isValidSeatCount(): boolean {
    return this.event.seatCount <= this.seatCountMax && this.event.seatCount >= this.codesRedeemed;
  }
  checkValid() {
    const validCheck = this.validChecks.get(this.activeStep);
    if (!this.isValidSeatCount) {
      return true;
    }
    if (validCheck != undefined || this.getClientName) {
      return false;
    }
    return true;
  }
  setGoBack(goBackParam: boolean): void {
    this.goBack = goBackParam;
  }

  @Watch("cancelConfirmed")
  cancelConfirmWatch(): void {
    if (this.cancelConfirmed) {
      this.completeCancel();
    }
  }
}
