
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import UserMenu from "@/components/Header/User/UserMenu.vue";
import SystemStatus from "@/components/SystemStatus/SystemStatus.vue";
import { BaseAuthModel, NavigationItem } from "@/store/modules/Base/model";
import Util from "@/auth/Util";
import { BasePopoverMenu as PopoverMenu } from "@cruciallearning/puddle";

import clLogoWhite from "@/assets/images/logos/cl-logo-white.svg";
import clMark from "@/assets/images/logos/cl-mark.svg";
import DrawerMenu from "@/components/Sidebar/DrawerMenu.vue";
import Icon from "@/components/Icons/Icon.vue";
import BaseSubmenu from "@/components/Header/BaseSubmenu.vue";

@Component({
  components: {
    BaseSubmenu,
    DrawerMenu,
    UserMenu,
    SystemStatus,
    Icon,
    PopoverMenu,
  },
  computed: {
    ...mapGetters("BaseModule", ["getMenuOnlyNavigationItems"]),
  },
})
export default class DarkHeader extends Vue {
  private readonly getMenuOnlyNavigationItems!: NavigationItem[];
  private sparseMenu = this.$route.path === "/system-check";
  private openDrawer = false;

  private submenuVisible = "";
  private submenuHideRequest = false;

  get logoSrc(): string {
    return this.sparseMenu ? clLogoWhite : clMark;
  }

  get authorizedItems(): NavigationItem[] {
    if (this.getMenuOnlyNavigationItems) {
      return this.getMenuOnlyNavigationItems.filter((e) => this.isAuthorized(e.auth));
    }
    return [];
  }

  toggleSidebar(): void {
    this.openDrawer = !this.openDrawer;
  }

  closeSidebar(): void {
    this.openDrawer = false;
  }

  get isAuthorized(): (auth: BaseAuthModel | undefined) => boolean {
    return (auth: BaseAuthModel | undefined) => {
      if (!auth) return false;
      return Util.isAuthorized(auth);
    };
  }

  isSubmenuVisible(name: string) {
    return this.submenuVisible === name;
  }

  showSubmenu(name: string) {
    this.submenuHideRequest = false;
    this.submenuVisible = name;
  }

  hideSubmenu(): void {
    // Debounce show/hide requests
    this.submenuHideRequest = true;
    setTimeout(this.doHideSubmenu, 50);
  }
  private doHideSubmenu(): void {
    if (this.submenuHideRequest) {
      this.submenuVisible = "";
      this.submenuHideRequest = false;
    }
  }

  submenuChevron(name: string): string {
    return this.submenuVisible === name ? "chevron-up" : "chevron-down";
  }
  submenuChevronColor(name: string): string {
    return this.submenuVisible === name ? "var(--gray-30)" : "white";
  }
}
