
import { Component, Prop, Vue } from "vue-property-decorator";
import { SubmenuItem } from "@/store/modules/Base/model";

@Component({})
export default class BaseSubmenu extends Vue {
  @Prop({ required: true }) readonly menu!: SubmenuItem[];

  onMouseEnter(): void {
    this.$emit("mouseenter");
  }
  onMouseLeave(): void {
    this.$emit("mouseleave");
  }
  onClick(): void {
    this.$emit("click");
  }
}
