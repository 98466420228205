import { BaseElement } from "@/store/modules/Base/model";
import { FooterTypes, HeaderTypes, RootState, SidebarTypes } from "./model";
import BaseModule from "@/store/modules/Base";
import ClientModule from "@/store/modules/Client";
import CourseModule from "@/store/modules/Course";
import EventModule from "@/store/modules/Event";
import FilterModule from "@/store/modules/Filter";
import StepModule from "@/store/modules/Step";
import UserModule from "@/store/modules/User";
import ModeratorModule from "@/store/modules/Moderator";
import { OnDemandModule } from "./modules/OnDemand";
import SecurityModule from "@/store/modules/Security";
import StatusModule from "@/store/modules/SystemStatus";
import IconModule from "@/store/modules/Icon";
import LocalizationModule from "@/store/modules/Localization";
import VirtualCourseModule from "@/store/modules/VirtualCourse";
import MocktaModule from "@/store/modules/mockta";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

/**
 * --< Default Vue store >--
 * The main file controls any global app settings that may
 * need to change from deep locations or non accessible
 * siblings or grandparents.
 */
export default new Vuex.Store({
  modules: {
    BaseModule,
    ClientModule,
    CourseModule,
    EventModule,
    FilterModule,
    StepModule,
    UserModule,
    MocktaModule,
    ModeratorModule,
    OnDemandModule,
    SecurityModule,
    StatusModule,
    IconModule,
    LocalizationModule,
    VirtualCourseModule,
  },
  state(): RootState {
    return {
      backgroundColorClass: "",
      footerType: FooterTypes.STEP,
      headerTitle: "",
      headerType: HeaderTypes.DARK,
      isDataLoading: false,
      isFooterVisible: false,
      isReady: false,
      isSidebarVisible: false,
      language: "en",
      lastRoute: "",
      navItem: undefined,
      sidebarType: SidebarTypes.FILTER,
    };
  },
  getters: {
    getElementByType(state: RootState): (type: number | string) => BaseElement | undefined {
      return (type: number | string): BaseElement | undefined =>
        state.navItem?.elements?.find((key) => key.type === type);
    },
  },
  mutations: {
    setRootState(state: RootState, rootStateOptions: Partial<RootState>): void {
      Object.assign(state, rootStateOptions);
    },
  },
  actions: {
    setReady({ state, commit }, isReady = true): void {
      if (state.isReady !== isReady) commit("setRootState", { isReady });
    },
  },
});
