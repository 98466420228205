
import { Component, Prop, Vue } from "vue-property-decorator";
import { directive as onClickaway } from "vue-clickaway";
import { mapState } from "vuex";
import { DateTime } from "luxon";
import { http } from "@/services/http";
import config from "@/config";
import Osano from "@/osano/model";

@Component({
  directives: { onClickaway: onClickaway },
  computed: { ...mapState("UserModule", ["user"]) },
})
export default class UserMenu extends Vue {
  @Prop({ required: false, type: Boolean }) readonly light?: boolean;

  private isUserMenuVisible = false;

  hideUserMenu(): void {
    this.isUserMenuVisible = false;
  }

  onLogoutClick(): void {
    this.$api.auditron.add(
      config.backendUrl,
      {
        action: "USER_LOGOUT",
        targetObjectId: this.$auth.authUser.id,
        targetCollection: "platformUser",
        tags: ["user", "logout", `${this.$auth.authUser.given_name} ${this.$auth.authUser.family_name}`],
      },
      http
    );

    this.$auth.logout();
  }

  showPrivacySettings(): void {
    this.onToggleUserMenu();
    Osano.cm.showDrawer("osano-cm-dom-info-dialog-open");
  }

  onProfileClick(): void {
    if (this.$router.currentRoute.path !== "/myprofile") {
      this.$router.push("/myprofile");
    }
    this.isUserMenuVisible = false;
  }

  onToggleUserMenu(): void {
    this.isUserMenuVisible = !this.isUserMenuVisible;
  }

  get name(): string {
    return `${this.$auth.authUser.given_name} ${this.$auth.authUser.family_name}`;
  }

  async onDownloadRedemptionReport(): Promise<void> {
    const csv = await this.$api.download.getRedemptionsReport();

    if (csv) {
      const timestamp = DateTime.now().toISO({ includeOffset: false });
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = `All_Clients_All_Codes_Report_${timestamp}.csv`;
      anchor.click();
    }
  }
}
