
import { Component, Vue } from "vue-property-decorator";
import LightHeader from "./Light/LightHeader.vue";
import DarkHeader from "./Dark/DarkHeader.vue";
import NoHeader from "./None/NoHeader.vue";
import { mapState } from "vuex";

@Component({
  components: { DarkHeader, LightHeader, NoHeader },
  computed: { ...mapState(["headerType"]) },
})
export default class BaseHeader extends Vue {}
