import api from "@/services/api";
import { Module } from "vuex";
import { OnDemandState, OnDemandEvent, FetchOnDemandEventsParams } from "./model";

export const OnDemandModule: Module<OnDemandState, unknown> = {
  namespaced: true,
  state(): OnDemandState {
    return {
      onDemandEvents: [],
    };
  },
  getters: {
    getOnDemandEvent(state: OnDemandState): (courseId: string) => OnDemandEvent | undefined {
      return (courseId: string) => state.onDemandEvents.find((onDemandEvent) => onDemandEvent.courseId === courseId);
    },
  },
  mutations: {
    AddOnDemandEvent(state: OnDemandState, onDemandEvent: OnDemandEvent): void {
      const existingEvent = state.onDemandEvents.find((event) => event.courseId === onDemandEvent.courseId);
      if (!existingEvent) {
        state.onDemandEvents.push(onDemandEvent);
      }
    },
  },
  actions: {
    async fetchOnDemandEventProgress({ commit }, { userId, courseId }: FetchOnDemandEventsParams): Promise<void> {
      const onDemandEvent = await api.onDemand.eventProgress(userId, courseId);
      onDemandEvent.courseId = courseId;
      commit("AddOnDemandEvent", onDemandEvent);
    },
  },
};
