import { event, events } from "./event";
import {
  CourseNoteModel,
  EventContentModel,
  EventDataModel,
  EventModel,
  EventSessionModel,
  EventState,
} from "@cruciallearning/puddle/models/event";
import { ClassTimeModel } from "@/store/modules/Event/model";
import { Module } from "vuex";
import api from "@/services/api";
import router from "@/router";
import { BasePlatformUser } from "../User/model";
import { AxiosError } from "axios";
import { CourseContentModel } from "../Course/model";
import { DateTime } from "luxon";
import { ClientModel } from "../Client/model";
import constants from "@/utils/constants";
import { getInstance } from "@cruciallearning/puddle/auth";
import utils from "@/utils";

const getSession = (state: EventState, sessionId: number): number => {
  return state.event.learningSessions.findIndex((item) => item.sessionId === sessionId);
};

const getEventDataModel = (eventContentModel: EventContentModel): EventDataModel => {
  return {
    salesForceId: eventContentModel.salesForceId,
    clientName: eventContentModel.clientName,
    courseId: eventContentModel.courseId,
    clientId: eventContentModel.clientId,
    courseContentStackId: eventContentModel.courseContentStackId,
    contentStackProductId: eventContentModel.contentStackProductId,
    details: eventContentModel.details,
    eventStatus: eventContentModel.eventStatus,
    includesPhysicalBook: eventContentModel.includesPhysicalBook,
    learningFormatType: eventContentModel.learningFormatType,
    registrationType: eventContentModel.registrationType,
    seatCount: eventContentModel.seatCount,
    title: eventContentModel.title,
    learningSessions: eventContentModel.learningSessions,
    startDate: eventContentModel.startDate,
    expirationDate: eventContentModel.expirationDate,
    sessionTimeZone: eventContentModel.sessionTimeZone,
    materialDownloads: eventContentModel.materialDownloads,
    clientCourseTokens: eventContentModel.clientCourseTokens,
    courseNotes: eventContentModel.courseNotes,
    referenceNumber: eventContentModel.referenceNumber,
    eap: eventContentModel.eap,
    expired: eventContentModel.expired,
    onDemandCourseId: eventContentModel.onDemandCourseId,
    onDemandEventId: eventContentModel.onDemandEventId,
    skipAutoReclaim: eventContentModel.skipAutoReclaim,
  };
};

const EventModule: Module<EventState, unknown> = {
  namespaced: true,
  state(): EventState {
    return {
      events: { ...events },
      event: { ...event },
    };
  },
  getters: {
    getTotalClassTime(state: EventState): ClassTimeModel {
      if (!state.event.learningSessions || state.event.learningSessions.length === 0) return { minutes: 0, hours: 0 };
      const total = state.event.learningSessions
        .map((item) => {
          if (item.sessionStart && item.sessionEnd) {
            const start = DateTime.fromISO(item.sessionStart);
            const end = DateTime.fromISO(item.sessionEnd);
            return end.toMillis() - start.toMillis();
          } else return 0;
        })
        .reduce((accum, item) => {
          return accum + item;
        }, 0);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 60);
      const minutes = Math.floor((total / (1000 * 60)) % 60);
      return { hours, minutes };
    },
    getEventsCount(state: EventState) {
      return state.events ? state.events.content.length : 0;
    },
    getAvailableLicenses(state: EventState): number {
      if (!state.event.clientCourseTokens) return 0;
      const token = state.event.clientCourseTokens.find((token) => token.courseId === state.event.courseId);
      if (token) return token.tokensAvailable;
      return 0;
    },
    getClientName(state: EventState): boolean {
      return !!state.event.clientName;
    },
  },
  mutations: {
    //--> Events ------------------------------------------------>
    setEventState(state: EventState, eventStateOptions: Partial<EventState>): void {
      Object.assign(state, eventStateOptions);
    },
    setEvent(state: EventState, eventContent: Partial<EventContentModel>): void {
      Object.assign(state.event, eventContent);
    },
    setEvents(state: EventState, eventModel: EventModel): void {
      state.events = eventModel;
    },
    clearEvents(state: EventState): void {
      state.events = { ...events };
    },

    clearEvent(state: EventState): void {
      state.event = {
        audit: {},
        salesForceId: "",
        clientName: "",
        courseId: "",
        clientId: "",
        courseContentStackId: "",
        contentStackProductId: "",
        courseName: "",
        details: "",
        eventStatus: "PUBLISHED",
        id: "",
        learningFormatType: constants.eventType.materialsOnly,
        learnerMaterials: [],
        includesPhysicalBook: false,
        preWorkMaterialIds: [],
        registrationType: "REGISTRATION",
        redemptionCodes: [],
        redeemedCount: 0,
        seatCount: 0,
        title: "",
        learningSessions: [],
        startDate: new Date(),
        expirationDate: new Date(),
        originalMaxCount: null,
        sessionTimeZone: {
          id: "",
          offset: 0,
          title: "",
        },
        registrationCode: {
          code: "",
          redeemedCount: 0,
          availableCount: 0,
          registrants: [],
          startDate: new Date(),
          endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
        },
        materialDownloads: [],
        clientCourseTokens: [],
        courseNotes: [],
        originalSeatCount: 0,
        onDemandCourseId: "",
        onDemandEventId: "",
        referenceNumber: "",
        eap: [],
        expired: false,
        skipAutoReclaim: false,
        practice: false,
        systemCheck: false,
        pendingCode: false,
      };
    },
    //--> Sessions ---------------------------------------------->
    addSession(state: EventState): void {
      state.event.learningSessions.push({
        sessionId: state.event.learningSessions.length + 1,
        trainers: [],
        moderators: [],
        date: undefined,
        sessionStart: undefined,
        sessionEnd: undefined,
        location: undefined,
      });
    },
    removeSession(state: EventState, sessionId: number): void {
      const sessionIndex = getSession(state, sessionId);
      if (sessionIndex >= 0) {
        state.event.learningSessions.splice(sessionIndex, 1);
        state.event.learningSessions.forEach((item, index) => (item.sessionId = index + 1));
      }
    },
    clearSessions(state: EventState): void {
      state.event.learningSessions = [];
    },
    updateSession(
      state: EventState,
      sessionChanges: { sessionId: number; sessionOptions: Partial<EventSessionModel> }
    ): void {
      const sessionIndex = getSession(state, sessionChanges.sessionId);
      if (sessionIndex >= 0) {
        Object.assign(state.event.learningSessions[sessionIndex], sessionChanges.sessionOptions);
      }
    },

    //--> Trainers ---------------------------------------------->
    addTrainer(state: EventState, trainerChanges: { sessionId: number; trainer: BasePlatformUser }): void {
      const sessionIndex = getSession(state, trainerChanges.sessionId);
      if (sessionIndex >= 0) {
        state.event.learningSessions[sessionIndex].trainers.push(trainerChanges.trainer);
      }
    },
    removeTrainer(state: EventState, trainerChanges: { sessionId: number; trainerIndex: number }): void {
      const sessionIndex = getSession(state, trainerChanges.sessionId);
      if (sessionIndex >= 0) {
        state.event.learningSessions[sessionIndex].trainers.splice(trainerChanges.trainerIndex, 1);
      }
    },

    //--> Moderators ---------------------------------------------->
    addModerator(state: EventState, moderatorChanges: { sessionId: number; moderator: BasePlatformUser }): void {
      const sessionIndex = getSession(state, moderatorChanges.sessionId);
      if (sessionIndex >= 0) {
        if (!state.event.learningSessions[sessionIndex].moderators) {
          state.event.learningSessions[sessionIndex].moderators = [];
        }
        state.event.learningSessions[sessionIndex].moderators.push(moderatorChanges.moderator);
      }
    },
    removeModerator(state: EventState, moderatorChanges: { sessionId: number; moderatorIndex: number }): void {
      const sessionIndex = getSession(state, moderatorChanges.sessionId);
      if (sessionIndex >= 0) {
        state.event.learningSessions[sessionIndex].moderators.splice(moderatorChanges.moderatorIndex, 1);
      }
    },
    updateBaseNote(state: EventState, noteChanges: Partial<CourseNoteModel>) {
      if (!state.event.courseNotes[0]) {
        if (getInstance().LEARNING_MANAGER) {
          state.event.courseNotes.push({ note: "", adminOnly: false });
        } else state.event.courseNotes.push({ note: "", adminOnly: true });
      }
      Object.assign(state.event.courseNotes[0], noteChanges);
    },
  },
  actions: {
    addEvent({ commit }): void {
      commit("setEvent", { ...event });
    },
    async createEvent({ state, commit }): Promise<void> {
      try {
        state.event.learningSessions.forEach((session) => {
          if (session.date) {
            session.sessionStart = utils.composeSessionTime(
              session.date,
              session.sessionStart,
              state.event.sessionTimeZone.id
            );
            session.sessionEnd = utils.composeSessionTime(
              session.date,
              session.sessionEnd,
              state.event.sessionTimeZone.id
            );
          }
        });
        const model = JSON.parse(JSON.stringify(state.event)) as EventContentModel;
        model.originalSeatCount = model.seatCount;
        const newEvent = await api.event.createEvent(model);
        if (newEvent) {
          await router.push(`event/${newEvent.id}/3`);
        }
      } catch (errRes) {
        console.error("Error occurred while creating event", errRes);
        const errorMessage = (errRes as Error).message;
        commit(`BaseModule/setError`, errorMessage, { root: true });
        commit(`StepModule/setStepState`, { completedSteps: [0], isComplete: false }, { root: true });
        await router.push(`events`);
      }
    },
    async getEvents({ commit }): Promise<void> {
      const eventList = await api.event.getEvents();
      commit("setEvents", eventList);
    },
    async updateEvent({ state, commit }, lastRoute: string): Promise<void> {
      if (state.event.learningSessions) {
        state.event.learningSessions.forEach((session) => {
          if (session.date) {
            session.sessionStart = utils.composeSessionTime(
              session.date,
              session.sessionStart,
              state.event.sessionTimeZone.id
            );
            session.sessionEnd = utils.composeSessionTime(
              session.date,
              session.sessionEnd,
              state.event.sessionTimeZone.id
            );
          }
        });
      }
      const updatedEvent = getEventDataModel(state.event);
      if (updatedEvent.title == "") updatedEvent.title = "null";
      try {
        await api.event.updateEvent(updatedEvent, state.event.id);
        commit(`clearEvents`);
      } catch (errRes) {
        const errorMessage = (errRes as Error).message;
        commit(`BaseModule/setError`, errorMessage || `Something bad happened`, { root: true });
        commit(`StepModule/setStepState`, { completedSteps: [0], isComplete: false }, { root: true });
      } finally {
        if (lastRoute) await router.push(lastRoute);
      }
    },
    async deleteEvent(_, eventId: string): Promise<void> {
      await api.event.deleteEvent(eventId);
    },
    async populateEditDetails({ commit }, event: EventContentModel): Promise<void> {
      const clientRes = await api.client.getClientById(event.salesForceId, `externalId`);
      const client = (clientRes as ClientModel).content[0];
      commit("setEvent", event);
      commit("setEvent", {
        clientCourseTokens: client.courseTokens,
        clientId: client.id,
        salesForceId: client.externalId,
        clientName: client.clientName,
      });
      commit("StepModule/setStepState", { isEditing: true }, { root: true });
    },
    setCourseSessions({ commit }, course: CourseContentModel): void {
      if (!course) return;
      commit("clearSessions");
      const count = course.recommendedSessionCount ? course.recommendedSessionCount : 5;
      for (let i = 0; i < count; i++) {
        commit("addSession");
      }
    },
    cascadeUpdateSessions(
      { state, commit },
      update: { date: string; sessionStart: string; sessionEnd: string; location: string }
    ): void {
      let i = 0;
      state.event.learningSessions.forEach((session) => {
        let sessionDate = DateTime.fromISO(update.date);
        sessionDate = sessionDate.set({ day: sessionDate.day + i });
        const day = sessionDate.toISO({ includeOffset: false });
        const model: Partial<EventSessionModel> = {};
        if (update.date) model.date = day;
        if (update.sessionStart) model.sessionStart = update.sessionStart;
        if (update.sessionEnd) model.sessionEnd = update.sessionEnd;
        if (update.location) model.location = update.location;
        commit("updateSession", {
          sessionId: session.sessionId,
          sessionOptions: model,
        });
        i++;
      });
    },
  },
};
export default EventModule;
